type Response = {
  data?: Record<string, string>
  err?: Error
}


export const goFetch = (url: string) => {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ key: "scheduledData" })
  })
    .then(response => response.json())
    .then(data => ({ data }))
    .catch(err => ({ err }));
}