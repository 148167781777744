import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { goFetch } from './components/goFetch';

const URL = 'https://server.kaicheng.workers.dev/post';

function App() {
  const [data, setData] = useState({});

  useEffect(() => {
    goFetch(URL).then((response) => setData(response));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="void(0)"
          target="#"
          rel="noopener noreferrer"
        >
          {JSON.stringify(data, null, 2)}
        </a>
      </header>
    </div>
  );
}

export default App;
